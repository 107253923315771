import { CollectionsDto, CreateUserCollectionRequest, UpdateCollectionRequest } from '@/Model/collections/types';
import CollectionCardComponent from '@/commoncomponents/CollectionCardComponent/CollectionCardComponent.vue';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ConfigureLearningLibraryCollection from '@/components/learningLibrary/ConfigureLearningLibraryCollection.vue';
import DeleteCollectionModal from '@/popupcomponents/collections/DeleteCollectionModal.vue';
import { createUserCollection, deleteUserCollection, getDefaultCollections, getUserCollections, updateCollection } from '@/services/collections/api';
import { CollectionCardData } from '@/utilities/cmsUtilities';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { AxiosError } from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import { Component, Vue } from 'vue-property-decorator';
import APP_CONST from "@/constants/AppConst";

@Component({
  components: {
    CollectionCardComponent,
    ConfigureLearningLibraryCollection,
    OffCanvas,
    BouncingPreloaderComponent,
    InfiniteLoading,
    DeleteCollectionModal,
  },
})
export default class MyCollectionsPage extends Vue {
  public defaultCollections: CollectionsDto[] = [];
  public userCollections: CollectionsDto[] = [];
  public selectedCollection: CollectionsDto | null = null;

  public totalItems = 0;
  public pagination = {
    page: 0,
    max: 25,
  };

  public isLoadingDefaultCollections = false;
  public getDefaultCollectionsErrorMessage: string | null = null;

  public isLoadingCollections = false;
  public getUserCollectionsErrorMessage: string | null = null;

  public showConfigureCollectionFlyout = false;

  public createUserCollectionErrorMessage: string | null = null;
  public isCreatingCollection = false;

  public updateCollectionErrorMessage: string | null = null;
  public isUpdatingCollection = false;

  public isDeleteCollectionModalVisible = false;
  public deleteCollectionErrorMessage: string | null = null;
  public isDeletingCollection = false;

  get collections(): CollectionsDto[] {
    return [...this.defaultCollections, ...this.userCollections];
  }

  get collectionCards(): CollectionCardData[] {
    return this.collections.map(({ id, name, posts, isLocked }) => ({
      collectionId: id.toString(),
      title: name,
      totalItems: posts.length,
      isLocked,
      contentImages: [],
    }));
  }

  created() {
    this.fetchDefaultCollections();
  }

  public addCollection(): void {
    this.selectedCollection = this.makeEmptyCollection();
    this.showConfigureCollectionFlyout = true;
  }

  public editCollection({ collectionId }: CollectionCardData): void {
    const collection = this.userCollections.find((collection) => collection.id.toString() === collectionId);

    if (!collection) {
      return;
    }

    this.selectedCollection = collection;
    this.showConfigureCollectionFlyout = true;
  }

  public cancelCollectionConfiguration(): void {
    this.showConfigureCollectionFlyout = false;
    this.selectedCollection = null;
  }

  public async addUserCollection(request: CreateUserCollectionRequest): Promise<void> {
    try {
      this.createUserCollectionErrorMessage = null;
      this.isCreatingCollection = true;

      const { data: { id, createdAt, modifiedAt } } = await createUserCollection(request);

      let generalCollection: CollectionsDto | undefined;
      if (this.userCollections[0].name === APP_CONST.DEFAULT_COLLECTION_NAME) {
        generalCollection = this.userCollections.shift();
      }

      this.userCollections.unshift({
        ...request,
        id,
        createdAt,
        modifiedAt,
      });

      if (generalCollection !== undefined) {
        this.userCollections.unshift(generalCollection);
      }

      this.showConfigureCollectionFlyout = false;
      this.selectedCollection = null;
    }
    catch (error) {
      this.createUserCollectionErrorMessage = (error as AxiosError | Error).message;
    }
    finally {
      this.isCreatingCollection = false;
    }
  }

  public async updateUserCollection(request: UpdateCollectionRequest): Promise<void> {
    try {
      this.updateCollectionErrorMessage = null;
      this.isUpdatingCollection = true;

      await updateCollection(request);

      const updatedCollectionIndex = this.userCollections.findIndex((collection) => collection.id === request.id);

      if (updatedCollectionIndex !== -1) {
        const updatedUserCollection = { ...this.userCollections[updatedCollectionIndex], ...request };
        this.userCollections.splice(updatedCollectionIndex, 1,);
        let generalCollection: CollectionsDto | undefined;
        if (this.userCollections[0].name === APP_CONST.DEFAULT_COLLECTION_NAME) {
          generalCollection = this.userCollections.shift();
        }
        this.userCollections.unshift(updatedUserCollection);
        if (generalCollection !== undefined) {
          this.userCollections.unshift(generalCollection);
        }
      }

      this.showConfigureCollectionFlyout = false;
      this.selectedCollection = null;
    }
    catch (error) {
      this.updateCollectionErrorMessage = (error as AxiosError | Error).message;
    }
    finally {
      this.isUpdatingCollection = false;
    }
  }

  public async saveCollection(): Promise<void> {
    if (!this.selectedCollection || this.isUpdatingCollection) {
      return;
    }

    if (!this.selectedCollection.id) {
      const { name, posts, isLocked } = this.selectedCollection;
      await this.addUserCollection({
        name,
        posts,
        isLocked,
        userId: APP_UTILITIES.getUserID(),
      });
    }
    else {
      await this.updateUserCollection({
        id: this.selectedCollection.id,
        name: this.selectedCollection.name,
        userId: APP_UTILITIES.getUserID(),
        posts: this.selectedCollection.posts,
      });
    }
  }

  public async fetchDefaultCollections(): Promise<void> {
    const isSharedCollectionsEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.sharedCollections);
    if (!isSharedCollectionsEnabled) {
      this.defaultCollections = [];
      return;
    }
    try {
      this.isLoadingDefaultCollections = true;

      const { data } = await getDefaultCollections();

      this.defaultCollections = data;
    }
    catch (error) {
      this.defaultCollections = [];
      this.getDefaultCollectionsErrorMessage = (error as AxiosError | Error).message;
    }
    finally {
      this.isLoadingDefaultCollections = false;
    }
  }

  public async handleInfiniteLoader($state: {
    loaded: () => void;
    complete: () => void;
  }): Promise<void> {
    try {
      this.isLoadingCollections = true;

      const newPage = this.pagination.page + 1;

      const {
        data: {
          items,
          totalItems
        }
      } = await getUserCollections({
        page: newPage,
        max: this.pagination.max
      });

      this.userCollections = [
        ...this.userCollections,
        ...items
      ];
      this.pagination.page = newPage;
      this.totalItems = totalItems;

      if (this.userCollections.length === totalItems) {
        $state.complete();
      }
      else {
        $state.loaded();
      }
    }
    catch (error) {
      this.getUserCollectionsErrorMessage = (error as AxiosError | Error).message;
      throw error;
    }
    finally {
      this.isLoadingCollections = false;
    }
  }

  openDeleteCollectionModal({ collectionId }: CollectionCardData): void {
    const collection = this.userCollections.find(collection => collection.id.toString() === collectionId);

    if (!collection) {
      return;
    }

    this.selectedCollection = collection;
    this.isDeleteCollectionModalVisible = true;
  }

  cancelDeleteCollection(): void {
    this.isDeleteCollectionModalVisible = false;
    this.selectedCollection = null;
  }

  async deleteCollection(): Promise<void> {
    if (!this.selectedCollection || this.isDeletingCollection) {
      return;
    }

    try {
      this.isDeletingCollection = true;
      this.deleteCollectionErrorMessage = null;

      const { id } = this.selectedCollection;

      await deleteUserCollection({ id });

      const deletedCollectionIndex = this.userCollections.findIndex((collection) => collection.id === id);

      if (deletedCollectionIndex !== -1) {
        const {
          data: {
            items,
            totalItems
          }
        } = await getUserCollections({
          page: 1,
          max: this.pagination.max * this.pagination.page
        });

        this.userCollections = items;
        this.totalItems = totalItems;

        const lastPage = Math.ceil(totalItems / this.pagination.max);

        if (this.pagination.page > lastPage) {
          this.pagination.page = lastPage;
        }
      }

      this.isDeleteCollectionModalVisible = false;
      this.selectedCollection = null;
    }
    catch (error) {
      this.deleteCollectionErrorMessage = (error as AxiosError | Error).message;
    }
    finally {
      this.isDeletingCollection = false;
    }
  }

  private makeEmptyCollection(): CollectionsDto {
    return {
      id: 0,
      name: '',
      posts: [],
      isLocked: false,
      userId: null,
      createdAt: '',
      modifiedAt: null,
    };
  }
}
